import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../../commons/functionals/authLogic/AuthContext";
import AdminService from "../../../../services/AdminService";
import FirmActions from "../../../../models/responses/FirmActions";
import JsonViewer from "../../../../commons/component/json_viewer/JsonViewer";
import {toast} from "react-hot-toast";
import Loading from "../../../../commons/component/loading/views/Loading";

export default function DashboardFirmsActionsPage() {

    const {user} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingFirmActions, setIsLoadingFirmActions] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [firmsActions, setFirmsActions] = useState<FirmActions[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const adminService = new AdminService();

    useEffect(() => {
        if (user !== null)
            setIsLoading(true);
            adminService.getFirmsActions(currentPage).then((pageOfUser) => {
                setFirmsActions(pageOfUser.content);
                setTotalPages(pageOfUser.totalPages);
            }).catch((_) => {
                setHasError(true);
            }).finally(() => setIsLoading(false));
    }, [user, currentPage]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const hasPayed = (uniqueIdentifier: string) => {
        setIsLoadingFirmActions(true);
        adminService
            .acceptPayment(uniqueIdentifier)
            .then((_) => {
                toast.success("Plata acceptata cu success");
                setFirmsActions(firmsActions.filter((fa) => fa.uniqueIdentifier !== uniqueIdentifier))
            })
            .catch((_) => toast.error("Am intampinat o eroare"))
            .finally(() => setIsLoadingFirmActions(false));
    }

    useEffect(() => {
        console.log(firmsActions);
    }, [firmsActions]);

    return (
        <>
            <h1 className={"my-4 text-2xl font-semibold"}>
                Actiuni ale firmelor care nu au fost platite prin terminalul de plata:
            </h1>
            {
                isLoading ?
                    <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                    </div> :
                    hasError ?
                        <div>err</div> :
                        firmsActions && firmsActions.length > 0 ? (
                            <>
                                <table className="w-full bg-white border border-gray-300">
                                    <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b">Firm Name</th>
                                        <th className="py-2 px-4 border-b">Firm Slug</th>
                                        <th className="py-2 px-4 border-b">Order Details</th>
                                        <th className="py-2 px-4 border-b">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {firmsActions.map((firmAction: FirmActions) => (
                                        <tr key={firmAction.uniqueIdentifier}>
                                            <td className="py-2 px-4 border-b text-center">{firmAction.firmName}</td>
                                            <td className="py-2 px-4 border-b text-center">{firmAction.firmSlug}</td>
                                            <td className="py-2 px-4 border-b text-center">
                                                <JsonViewer json={JSON.parse(firmAction.details)}/>
                                            </td>
                                            <td className="w-44 py-2 px-4 border-b text-center ">
                                                {isLoadingFirmActions ?
                                                    <Loading color={"fill-accent-main-500"}/> :
                                                    <button
                                                        disabled={isLoadingFirmActions}
                                                        onClick={() => hasPayed(firmAction.uniqueIdentifier)}
                                                        className={"bg-accent-main-500 rounded-lg p-3 text-white"}>
                                                        Accepta plata
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 0}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Previous
                                    </button>
                                    <span className="text-gray-600">
                                        Page {currentPage + 1} of {totalPages}
                                    </span>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages - 1}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="text-center text-gray-600">No firms actions found.</div>
                        )
            }
        </>
    )
}