import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../../commons/component/loading/views/Loading";
import VerifyTokenService from "../../services/VerifyTokenService";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";

export default function VerifyTokenPage() {

    const {token} = useParams<{ token: string }>();
    const navigate = useNavigate();
    const [validated, setValidated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (token === undefined)
            navigate(ApplicationRoutes.root);

        new VerifyTokenService().verifyToken(token!).then((response) => {
            setValidated(response);
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
        })
    }, [navigate, token]);

    return (
        <div className={"h-screen w-screen flex justify-center items-center"}>
            {
                loading ?
                    <div>
                        <Loading color={"fill-accent-main-500"}/>
                        <p>Iti verificam emailul!</p>
                    </div>
                    :
                    <div>
                        <p>
                            {
                                validated ?
                                    "Emailul validat cu success. Un admin va confirma contul si te vei putea loga atunci!" :
                                    "Exista o eroare, incearca din nou!"
                            }
                        </p>
                    </div>
            }
        </div>
    );
}