import {Link, useLocation, useParams} from "react-router-dom";
import React, {useContext, useEffect, useMemo, useState} from "react";
import AiDocumentCheckerService from "../../../services/AiDocumentCheckerService";
import AnalyseTask from "../../../models/AnalyseTask";
import {apiRoutesAiDocumentChecker} from "../../../commons/environment/EnvironmentRoutes";
import {IoChevronBack} from "react-icons/io5";
import AiDocumentCheckerRouter from "../../../routers/AiDocumentCheckerRouter";
import Utils from "../../../commons/functionals/utils/Utils";
import Loading from "../../../commons/component/loading/views/Loading";
import {toast, Toaster} from "react-hot-toast";
import CreateProviderRequest from "../../../models/requests/CreateProviderRequest";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import FirmProviderDetails from "../../../models/FirmProviderDetails";
import ProvidersService from "../../../services/ProvidersService";
import UnlinkProviderRequest from "../../../models/requests/UnlinkProviderRequest";

export default function AiDocumentCheckerViewTask() {

    const {user} = useContext(AuthContext);
    const {taskSlug} = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingAddingProvider, setIsLoadingAddingProvider] = useState<boolean>(false);
    const [analysisTask, setAnalysisTask] = useState<AnalyseTask | null>(null);

    const [isLoadingProviders, setIsLoadingProviders] = useState<boolean>(false);
    const [providers, setProviders] = useState<FirmProviderDetails[]>([]);
    const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
    const [isLoadingSavingProviders, setIsLoadingSavingProviders] = useState<boolean>(false);

    const [viewMatrix, setViewMatrix] = useState<boolean>(false);

    const service = new AiDocumentCheckerService();
    const providerService = new ProvidersService();

    const location = useLocation();

    const [originalPdf, setOriginalPdf] = useState<string | null>(null);
    const [highlightedPdf, setHighlightedPdf] = useState<string | null>(null);

    useEffect(() => {
        if (taskSlug === undefined)
            return;

        setIsLoading(true);
        setIsLoadingProviders(true);
        service.getTask(user!.firmSlug, taskSlug)
            .then((response) => {
                setAnalysisTask(response);

                service.getPdf(user!.firmSlug, taskSlug ?? "", response.analysisDetails.photoshopDetectedName).then((response) => setOriginalPdf(URL.createObjectURL(response)));
                service.getPdf(user!.firmSlug, taskSlug ?? "", response.analysisDetails.photoshopMatrixDetectedName).then((response) => setHighlightedPdf(URL.createObjectURL(response)));

                if (response.analysisDetails.providerSelected != null) {
                    setSelectedProvider(response.analysisDetails.providerSelected);
                    providerService.getAllProviders()
                        .then(rsp => {
                            setProviders(rsp);
                        })
                        .catch((_) => toast.error("Am intampiat o eroare la preluarea furnizorilor. Incercati mai tarziu"))
                }
            })
            .catch(err => console.log(err))
            .finally(() => {
                setIsLoading(false);
                setIsLoadingProviders(false);
            });
    }, [taskSlug]);

    const toggleMatrixView = () => {
        setViewMatrix(!viewMatrix);
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProvider(event.target.value);
    };

    const saveNewProvider = () => {
        if (selectedProvider === analysisTask!.analysisDetails.providerSelected)
            return;

        let req: UnlinkProviderRequest = {
            oldProviderIdentification: analysisTask?.analysisDetails.providerSelected ?? "none",
            newProviderIdentification: selectedProvider ?? "none",
            firmSlug: user!.firmSlug,
            documentName: analysisTask?.analysisDetails.initialDocumentName ?? "NO NAME",
            documentType: analysisTask?.analysisDetails.documentType ?? "NO TYPE",
            taskSlug: taskSlug ?? ""
        };

        setIsLoadingSavingProviders(true);
        service.changeProvider(user!.firmSlug, req)
            .then((_) => setSelectedProviderInObject())
            .catch((_) => toast.error("Am intampinat o eroare"))
            .finally(() => setIsLoadingSavingProviders(false));
    }

    const setSelectedProviderInObject = () => {
        const updatedTask: AnalyseTask = {
            ...analysisTask,
            analysisDetails: {
                ...(analysisTask!.analysisDetails),
                providerSelected: selectedProvider
            },
            slug: analysisTask!.slug,
            userSlugInitiator: analysisTask!.userSlugInitiator,
            startedAt: analysisTask!.startedAt,
        };
        setAnalysisTask(updatedTask);
    }

    const renderTableData = () => {
        if (!analysisTask!.analysisDetails) return null;

        const details = analysisTask!.analysisDetails.details;

        return details.map((item: any, index: number) => {
            return (
                <tr key={index} className={"text-start"}>
                    <td>{item.type}</td>
                    <td>{item.mentionText}</td>
                </tr>
            );
        });
    };

    const setProviderToNull = () => {
        const updatedTask: AnalyseTask = {
            ...analysisTask,
            analysisDetails: {
                ...(analysisTask!.analysisDetails),
                detectedProviderDetails: null,
            },
            slug: analysisTask!.slug,
            userSlugInitiator: analysisTask!.userSlugInitiator,
            startedAt: analysisTask!.startedAt,
        };
        setAnalysisTask(updatedTask);
    }

    const addProvider = () => {
        if (analysisTask?.analysisDetails.detectedProviderDetails === null)
            return;

        if (user === null)
            return;

        setIsLoadingAddingProvider(true);
        let req: CreateProviderRequest = {
            name: analysisTask?.analysisDetails.detectedProviderDetails.name ?? "",
            firmSlug: user.firmSlug,
            cui: analysisTask?.analysisDetails.detectedProviderDetails.cui ?? null,
            address: analysisTask?.analysisDetails.detectedProviderDetails.address ?? null,
            gei: analysisTask?.analysisDetails.detectedProviderDetails.gei ?? null,
            taskSlug: taskSlug ?? ""
        };
        service.createProvider(user!.firmSlug, req).then((res) => {
            setProviderToNull();
            toast.success(res);
        })
            .catch((_) => toast.error("Am intampinat o eroare!"))
            .finally(() => setIsLoadingAddingProvider(false));
    }

    const updateProvider = () => {
        if (analysisTask?.analysisDetails.detectedProviderDetails === null)
            return;

        if (user === null)
            return;

        setIsLoadingAddingProvider(true);
        let req: CreateProviderRequest = {
            name: analysisTask?.analysisDetails.detectedProviderDetails.name ?? "",
            firmSlug: user.firmSlug,
            cui: analysisTask?.analysisDetails.detectedProviderDetails.cui ?? null,
            address: analysisTask?.analysisDetails.detectedProviderDetails.address ?? null,
            gei: analysisTask?.analysisDetails.detectedProviderDetails.gei ?? null,
            taskSlug: taskSlug ?? ""
        };
        service.updateProvider(user!.firmSlug, req).then((res) => {
            setProviderToNull();
            toast.success(res);
        })
            .catch((_) => toast.error("Am intampinat o eroare!"))
            .finally(() => setIsLoadingAddingProvider(false));
    }

    const buildMetadata = (value: number | null): string => {
        return (value ?? "0") < 30 ?
            "text-green-500" :
            (value ?? "0") < 60 ?
                "text-yellow-500 font-main-medium" :
                "text-error font-main-bold";
    }

    return (
        <>
            <div><Toaster/></div>
            {
                isLoading ?
                    <Loading color={"fill-accent-main-500"}/> :
                    <div className={"h-screen"}>
                        <div className="flex items-center justify-between pb-4 h-[50px]">
                            <div className={"flex items-center gap-2"}>
                                <Link
                                    className={"p-3 text-white rounded-lg bg-accent-main-500"}
                                    to={location.pathname.replace(AiDocumentCheckerRouter.viewTaskEntrance + analysisTask!.slug, "")}>
                                    <IoChevronBack/>
                                </Link>
                                <h1 className="text-text-main-color-500 text-xl font-main-bold">
                                    Document cu ID: {analysisTask?.slug}
                                </h1>
                            </div>
                            <div>
                                <Link
                                    to={location.pathname.replace(AiDocumentCheckerRouter.viewTaskEntrance + analysisTask!.slug, "") + AiDocumentCheckerRouter.education}
                                    className={"p-3 text-white rounded-lg bg-accent-main-500"}>
                                    Vezi detalii educatie
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-row h-full pb-4 bg-gray-50 shadow-xl">
                            <div className="w-2/3 h-full relative">
                                <div className="absolute top-0 left-0 w-full z-10 p-5">
                                    <div onClick={toggleMatrixView}
                                         className={"p-3 w-fit text-white rounded-lg bg-accent-main-500"}>
                                        {
                                            !viewMatrix ? "Vezi document original" : "Vezi radiografie"
                                        }
                                    </div>
                                </div>

                                <iframe
                                    title={analysisTask!.slug}
                                    src={viewMatrix ? highlightedPdf! : originalPdf!}
                                    className="w-full h-full rounded-lg"
                                />
                            </div>

                            <div className="w-1/3 h-full bg-gray-50 p-4 rounded-lg">
                                <h2 className="text-lg font-main-bold">Informatii Extrase din document:</h2>
                                <div>Tip de document:
                                    <span className={"font-main-medium"}>
                                        {Utils.convertToText(analysisTask!.analysisDetails.documentType)}
                                    </span>
                                </div>
                                <a className={"hover:underline"}
                                   href={apiRoutesAiDocumentChecker.viewDocument.replace('TASK_SLUG', taskSlug ?? "").replace("DOCUMENT_NAME", analysisTask!.analysisDetails.originalDocumentName)}>
                                    Descarca document original
                                </a>
                                <table className="table-auto w-full mt-2">
                                    <thead>
                                    <tr>
                                        <th className="py-2 text-start">Camp</th>
                                        <th className="py-2 text-start">Valoare</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderTableData()}
                                    <tr key={90} className={"text-start"}>
                                        <td>Autorul este verificat</td>
                                        <td>{
                                            (analysisTask!.analysisDetails.authorMatched ?? false) ?
                                                <span className={"text-green-500"}>DA</span> :
                                                <span className={"text-error font-main-bold"}>NU</span>
                                        }</td>
                                    </tr>
                                    <tr key={91} className={"text-start"}>
                                        <td>Document fraudulent</td>
                                        <td className={buildMetadata(analysisTask!.analysisDetails.fraudPercent)}>
                                            {analysisTask!.analysisDetails.fraudPercent ?? "0"} %
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                {
                                    isLoadingProviders ?
                                        <Loading/> : analysisTask?.analysisDetails.providerSelected === null ?
                                            <p className={"py-3 font-main-bold"}>Nu a fost ales niciun furnizor</p> :
                                            <>
                                                <div className={"font-main-medium mt-5"}>
                                                    Furnizorul selectat:
                                                </div>
                                                <div className={"flex gap-2 flex-row"}>
                                                    <select
                                                        value={selectedProvider || ''}
                                                        onChange={handleChange}
                                                        className="appearance-none w-full bg-white border border-accent-main-500 px-4 py-2 rounded-lg shadow-lg outline-none"
                                                    >
                                                        <option value="" disabled>Selecteaza un furnizor</option>
                                                        {providers.map((provider) => (
                                                            <option
                                                                key={provider.providerNameIdentification}
                                                                value={provider.providerNameIdentification || ''}
                                                            >
                                                                {provider.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {
                                                        selectedProvider === analysisTask!.analysisDetails.providerSelected ?
                                                            <></> :
                                                            <button
                                                                disabled={isLoadingSavingProviders}
                                                                onClick={saveNewProvider}
                                                                className={"bg-accent-main-500 p-2 rounded-lg shadow-lg w-1/2 text-white flex justify-center items-center font-main-medium"}>
                                                                {
                                                                    isLoadingSavingProviders ? <Loading/> : "Salveaza"
                                                                }
                                                            </button>
                                                    }
                                                </div>
                                            </>
                                }
                                {
                                    analysisTask?.analysisDetails.detectedProviderDetails === null ?
                                        <></> :
                                        <div
                                            className={"border border-accent-main-500 m-2 rounded-lg shadow-lg bg-white p-2 flex justify-center items-center flex-col gap-2"}>
                                            <p className={"font-main-medium text-accent-main-500 text-center"}>
                                                {
                                                    analysisTask?.analysisDetails.detectedProviderDetails.isNew ?
                                                        <>
                                                            Am detectat un nou furnizor. <br/>
                                                            Doresti sa il salvezi?
                                                        </> :
                                                        <>
                                                            Acest furnizor nu are toate datele complete. <br/>
                                                            Doresti sa le salvezi complet?
                                                        </>
                                                }
                                            </p>
                                            <button
                                                disabled={isLoadingAddingProvider}
                                                className={"bg-accent-main-500 rounded-lg text-white p-2 transition-all hover:bg-accent-main-600"}
                                                onClick={() => analysisTask?.analysisDetails.detectedProviderDetails?.isNew ?? false ? addProvider() : updateProvider()}>
                                                {isLoadingAddingProvider ?
                                                    <Loading/> : analysisTask?.analysisDetails.detectedProviderDetails.isNew ? "Adauga furnizor" : "Salveaza datele noi"}
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}