import {AxiosResponse} from "axios";
import axiosInstance from "../commons/functionals/authLogic/AxiosInterceptors";
import {apiRoutesAiDocumentChecker} from "../commons/environment/EnvironmentRoutes";
import {toast} from "react-hot-toast";
import AnalyseTask from "../models/AnalyseTask";
import {Page} from "../commons/functionals/general_models/Page";
import CreateProviderRequest from "../models/requests/CreateProviderRequest";
import UnlinkProviderRequest from "../models/requests/UnlinkProviderRequest";

export default class AiDocumentCheckerService {

    async getAllTasks(firmSlug: string, currentPage: number): Promise<Page<AnalyseTask>> {
        const url = apiRoutesAiDocumentChecker.getMyTasks.replace("FIRM_SLUG", firmSlug) + "?page=" + currentPage;
        console.log(url);
        const response: AxiosResponse<Page<AnalyseTask>> = await axiosInstance.get<Page<AnalyseTask>>(url);
        return response.data;
    }

    async getTask(firmSlug: string, slug: string): Promise<AnalyseTask> {
        const response: AxiosResponse<AnalyseTask> = await axiosInstance.get<AnalyseTask>(apiRoutesAiDocumentChecker.getMyTasks.replace("FIRM_SLUG", firmSlug) + "/" + slug);
        return response.data;
    }

    async createProvider(firmSlug: string, createRequest: CreateProviderRequest): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.post<string>(apiRoutesAiDocumentChecker.baseProvider.replace("FIRM_SLUG", firmSlug), createRequest);
        return response.data;
    }

    async changeProvider(firmSlug: string, unlinkProviderRequest: UnlinkProviderRequest): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.post<string>(apiRoutesAiDocumentChecker.changeProvider.replace("FIRM_SLUG", firmSlug), unlinkProviderRequest);
        return response.data;
    }

    async updateProvider(firmSlug: string, createRequest: CreateProviderRequest): Promise<string> {
        const response: AxiosResponse<string> = await axiosInstance.put<string>(apiRoutesAiDocumentChecker.baseProvider.replace("FIRM_SLUG", firmSlug), createRequest);
        return response.data;
    }

    async createTask(firmSlug: string, file: File | null): Promise<String> {
        const formData = new FormData();

        if (file !== null) {
            formData.append('file', file);
        } else {
            toast.error("Ataseaza un document pentru a fi scanat");
        }
        const response: AxiosResponse<String> = await axiosInstance.post<String>(apiRoutesAiDocumentChecker.createTask.replace("FIRM_SLUG", firmSlug), formData, {
            headers: {'Content-Type': 'multipart/form-data'},
            withCredentials: true,
        });
        return response.data;
    }

    async getPdf(firmSlug: string, taskSlug: string, documentName: string): Promise<Blob> {
        const response: AxiosResponse<Blob> = await axiosInstance.get<Blob>(apiRoutesAiDocumentChecker.viewDocument.replace("FIRM_SLUG", firmSlug).replace('TASK_SLUG', taskSlug).replace("DOCUMENT_NAME", documentName), {responseType: "blob"});
        return response.data;
    }
}