import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";

export default function Home() {
    const navigator = useNavigate();

    useEffect(() => {
        navigator(ApplicationRoutes.dashboard);
    }, [navigator]);

    return (<></>);
}