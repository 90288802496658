import axios from "axios";

const axiosInstance = axios.create({
    withCredentials: true,
});

// axiosInstance.interceptors.request.use(
//     (config) => {
//         const cookies = document.cookie.split(";");
//
//         const myCookie = cookies.find((cookie) => {
//             const [name] = cookie.split("=").map((c) => c.trim());
//             return name === "authMarkDeploy";
//         });
//
//         if (myCookie) {
//             const [, value] = myCookie.split("=").map((c) => c.trim());
//             config.headers["Authorization"] = `Bearer ${value}`;
//         }
//
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );


export default axiosInstance;