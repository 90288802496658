import FirmSettingsRouter from "../../routers/FirmSettingsRouter";
import {Route, Routes} from "react-router-dom";
import React from "react";
import FirmDetailsPage from "./components/FirmDetailsPage";
import FirmSubscriptionPage from "./components/FirmSubscriptionPage";
import FirmEmployeesPage from "./components/FirmEmployeesPage";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";
import PrivateAccessRoute from "../../commons/functionals/authLogic/route_permitters/PrivateAccessRoute";

export default function FirmSettingsRootPage() {

    return (
        <Routes>
            <Route path={FirmSettingsRouter.root}
                   element={
                       <PrivateAccessRoute
                           permittedApplicationAccess={"*"}
                           permittedRole={[UserRoles.ROLE_ACCOUNT_ADMIN]}>
                           <FirmDetailsPage/>
                       </PrivateAccessRoute>
                   }/>
            <Route path={FirmSettingsRouter.subscription}
                   element={
                       <PrivateAccessRoute
                           permittedApplicationAccess={"*"}
                           permittedRole={[UserRoles.ROLE_ACCOUNT_ADMIN]}>
                           <FirmSubscriptionPage/>
                       </PrivateAccessRoute>
                   }/>
            <Route path={FirmSettingsRouter.firmUsers}
                   element={
                       <PrivateAccessRoute
                           permittedApplicationAccess={"*"}
                           permittedRole={[UserRoles.ROLE_ACCOUNT_ADMIN]}>
                           <FirmEmployeesPage/>
                       </PrivateAccessRoute>
                   }/>
        </Routes>
    )
}