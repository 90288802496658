import React from "react";
import {Link} from "react-router-dom";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";

export default function AccessDenied() {
    return (
        <div className={"h-full w-full flex justify-center items-center"}>

            <div
                className="m-8 p-5 flex flex-col items-center justify-center bg-white w-[400px]">
                <p className={"text-xl mb-5"}>Scuze, dar nu ai access</p>
                <Link to={ApplicationRoutes.dashboard}
                      className={"text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"}>
                    Catre panou de bord
                </Link>
            </div>
        </div>
    );
}