import {Route, Routes} from "react-router-dom";
import AiDocumentCheckerRouter from "../../routers/AiDocumentCheckerRouter";
import AiDocumentCheckerDashboard from "./views/AiDocumentCheckerDashboard";
import React from "react";
import AiDocumentCheckerViewTask from "./views/AiDocumentCheckerViewTask";
import AiDocumentCheckerEducationPage from "./views/AiDocumentCheckerEducationPage";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";
import PrivateAccessRoute from "../../commons/functionals/authLogic/route_permitters/PrivateAccessRoute";
import AiDocumentCheckerFirmAdminDashboard from "./views/AiDocumentCheckerFirmAdminDashboard";

export default function AiDocumentCheckerRoot() {
    return (
        <>
            <Routes>
                <Route path={AiDocumentCheckerRouter.root}
                       element={
                           <PrivateAccessRoute
                               permittedApplicationAccess={process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'hash'}
                               permittedRole={[UserRoles.ROLE_EMPLOYEE]}>
                               <AiDocumentCheckerDashboard/>
                           </PrivateAccessRoute>
                       }/>
                <Route path={AiDocumentCheckerRouter.firmAdminRoot}
                       element={
                           <PrivateAccessRoute
                               permittedApplicationAccess={process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'hash'}
                               permittedRole={[UserRoles.ROLE_ACCOUNT_ADMIN]}>
                               <AiDocumentCheckerFirmAdminDashboard/>
                           </PrivateAccessRoute>
                       }/>
                <Route path={AiDocumentCheckerRouter.education}
                       element={
                           <PrivateAccessRoute
                               permittedApplicationAccess={process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'hash'}
                               permittedRole={[UserRoles.ROLE_EMPLOYEE]}>
                               <AiDocumentCheckerEducationPage/>
                           </PrivateAccessRoute>
                       }/>
                <Route path={AiDocumentCheckerRouter.viewTask}
                       element={
                           <PrivateAccessRoute
                               permittedApplicationAccess={process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'hash'}
                               permittedRole={[UserRoles.ROLE_EMPLOYEE]}>
                               <AiDocumentCheckerViewTask/>
                           </PrivateAccessRoute>
                       }/>
            </Routes>
        </>
    );
}