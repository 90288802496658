import FirmProviderDetails from "../models/FirmProviderDetails";
import {AxiosResponse} from "axios";
import axiosInstance from "../commons/functionals/authLogic/AxiosInterceptors";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import ProviderDetailsResponse from "../models/responses/ProviderDetailsResponse";

export default class ProvidersService {
    async getAllProviders(): Promise<FirmProviderDetails[]> {
        const response: AxiosResponse<FirmProviderDetails[]> = await axiosInstance.get<FirmProviderDetails[]>(apiRoutes.getMyProviders);
        return response.data;
    }

    async getProviderDetails(documentNameIdentification: string): Promise<ProviderDetailsResponse> {
        const response: AxiosResponse<ProviderDetailsResponse> = await axiosInstance.get<ProviderDetailsResponse>(apiRoutes.getProviderDocuments.replaceAll("DOCUMENT_NAME", documentNameIdentification));
        return response.data;
    }
}