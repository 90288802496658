import React from "react";
import LoadingInterface from "../functionals/models/LoadingInterface";
import {AiOutlineLoading} from "react-icons/ai";

const Loading: React.FC<LoadingInterface> = ({color = "fill-white"}) => {
    return (
        <div className={"flex justify-start items-center"}>
            <AiOutlineLoading className={"w-5 h-5 text-gray-200 animate-spin " + color}/>
        </div>
    );
}

export default Loading;