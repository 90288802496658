import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../../commons/functionals/authLogic/AuthContext";
import User from "../../../../commons/component/header/functionals/models/User";
import AdminService from "../../../../services/AdminService";
import UserFirmDetails from "../../../../commons/component/header/functionals/models/UserFirmDetails";
import {toast, Toaster} from "react-hot-toast";
import Loading from "../../../../commons/component/loading/views/Loading";

export default function DashboardUserRequestsPage() {

    const {user} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [userRequests, setUserRequests] = useState<UserFirmDetails[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const adminService = new AdminService();
    const [isLoadingAcceptingState, setIsLoadingAcceptingState] = useState<boolean>(false);

    useEffect(() => {
        if (user !== null)
            adminService.getPendingUsers(currentPage).then((pageOfUser) => {
                setUserRequests(pageOfUser.content);
                setTotalPages(pageOfUser.totalPages);
            }).catch((_) => {
                setHasError(true);
            }).finally(() => setIsLoading(false));
    }, [user, currentPage]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    const onAccept = (slug: string) => {
        setIsLoadingAcceptingState(true);
        adminService.acceptUser(slug)
            .then((_) => {
                setUserRequests(userRequests.filter(u => u.user.slug !== slug));
                toast.success("User accepted successfully");
            })
            .catch((_) => toast.error("Error accepting user"))
            .finally(() => setIsLoadingAcceptingState(false));
    };

    const onReject = (slug: string) => {
        setIsLoadingAcceptingState(true);
        adminService.rejectUser(slug)
            .then((_) => {
                setUserRequests(userRequests.filter(u => u.user.slug !== slug));
                toast.success("User deleted successfully");
            })
            .catch((_) => toast.error("Error deleting user"))
            .finally(() => setIsLoadingAcceptingState(false));
    };

    return (
        <>
            <div><Toaster/></div>
            <h1 className={"pt-8 px-8 text-4xl font-bold"}>Welcome back, {user?.username}</h1>
            <h1 className={"mt-4 px-8 text-2xl font-semibold"}>Your user register requests</h1>
            {
                isLoading ?
                    <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                    </div> :
                    hasError ?
                        <div>err</div> :
                        userRequests && userRequests.length > 0 ? (
                            <>
                                <table className="min-w-full bg-white border border-gray-300">
                                    <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b">Username</th>
                                        <th className="py-2 px-4 border-b">Email</th>
                                        <th className="py-2 px-4 border-b">Role</th>
                                        <th className="py-2 px-4 border-b">Firm Name</th>
                                        <th className="py-2 px-4 border-b">Options</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {userRequests.map((user: UserFirmDetails) => (
                                        <tr key={user.user.slug}>
                                            <td className="py-2 px-4 border-b text-center">{user.user.username}</td>
                                            <td className="py-2 px-4 border-b text-center">{user.user.email}</td>
                                            <td className="py-2 px-4 border-b text-center">{user.user.role.name.replaceAll('_', ' ').replace('ROLE', '')}</td>
                                            <td className="py-2 px-4 border-b text-center">{user.firmName}</td>
                                            <td className="py-2 px-4 border-b">
                                                {
                                                    isLoadingAcceptingState
                                                        ? <div className={"h-[57px] flex justify-center items-center"}><Loading color={"fill-accent-main-500"}/></div>
                                                        : <div className="flex items-center justify-center">
                                                            <button
                                                                onClick={() => onAccept(user.user.slug)}
                                                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mr-2"
                                                            >
                                                                Smash
                                                            </button>
                                                            <button
                                                                onClick={() => onReject(user.user.slug)}
                                                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                                                            >
                                                                Pass
                                                            </button>
                                                        </div>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 0}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Previous
                                    </button>
                                    <span className="text-gray-600">
                                        Page {currentPage + 1} of {totalPages}
                                    </span>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages - 1}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="text-center text-gray-600">No user requests found.</div>
                        )


            }
        </>
    )
}