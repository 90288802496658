import React, {createContext, useEffect, useState} from "react";
import User from "../../component/header/functionals/models/User";
import HeaderService from "../../component/header/functionals/services/Service";

interface AuthContextType {
    user: User | null;
    setUser: (user: User | null) => void;
}

export const AuthContext = createContext<AuthContextType>({
    user: null,
    setUser: () => {
    }
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        new HeaderService().getUserDetails().then((responseUser) => {
            setUser(responseUser.data);
        }).catch(_ => {
        });
    }, []);

    return (
        <AuthContext.Provider value={{user, setUser}}>
            {children}
        </AuthContext.Provider>
    );
};
