import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import NetopiaRequest from '../../models/NetopiaRequest';
import ApplicationRoutes from "../../commons/environment/AppRoutingName";

export default function PaymentPage() {
    const location = useLocation();
    const parsedParams = location.state as NetopiaRequest;
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (parsedParams == null) return;

        if (parsedParams && formRef.current) {
            formRef.current.submit();
        }
    }, [parsedParams]);

    return (
        <>
            {
                parsedParams == null ?
                    <div className="pt-[60px] h-screen flex p-4 items-center justify-center flex-col gap-10">
                        <p className={"text-green-500 text-2xl text-center"}>
                            Multumim pentru plata. Un administrator va verifica daca plata a fost efectuata prin
                            transfer bancar.
                        </p>
                        <a href={ApplicationRoutes.dashboard}
                           className={"bg-accent-main-500 text-white rounded-lg shadow-lg px-3 py-1.5"}>
                            Acasa
                        </a>
                    </div>
                    :
                    <div className="pt-[60px] h-screen flex p-4 items-center justify-center flex-col gap-10">
                        <p>Redirectionam catre portalul de plata</p>
                        <form
                            ref={formRef}
                            action={parsedParams.linkToSend ?? "/"}
                            method="post"
                            id="payment-form"
                            className={"w-full flex items-center justify-center"}
                        >
                            <input type="hidden" name="env_key" value={parsedParams.env_key}/>
                            <input type="hidden" name="data" value={parsedParams.data}/>
                            <button type="submit" id="submit"
                                    className={"bg-accent-main-500 text-white rounded-lg shadow-lg px-3 py-1.5"}>
                                Plateste!
                            </button>
                        </form>
                    </div>
            }
        </>
    );
}
