import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Loading from "../../../commons/component/loading/views/Loading";
import FirmProviderDetails from "../../../models/FirmProviderDetails";
import {IoArrowForward} from "react-icons/io5";
import {toast} from "react-hot-toast";
import ProvidersService from "../../../services/ProvidersService";
import ProviderRouter from "../../../routers/ProviderRouter";

export default function ProvidersDashboard() {

    const [isLoadingProviders, setIsLoadingProviders] = useState<boolean>(false);
    const [providers, setProviders] = useState<FirmProviderDetails[]>([]);
    const service = new ProvidersService();
    const location = useLocation();

    useEffect(() => {
        setIsLoadingProviders(true);
        service.getAllProviders()
            .then(response => {
                setProviders(response);
            })
            .catch((_) => toast.error("Am intampiat o eroare. Incercati mai tarziu"))
            .finally(() => setIsLoadingProviders(false));
    }, []);

    return (
        <>
            {
                isLoadingProviders ?
                    <div className={"flex justify-center gap-3 items-center"}>
                        <p>Se incarca furnizorii</p>
                        <Loading color={"fill-accent-main-500"}/>
                    </div> :
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-accent-main-500 rounded-xl">
                            <thead>
                            <tr className="text-white">
                                <th className="py-2 px-4 border-b">Nume</th>
                                <th className="py-2 px-4 border-b">CUI</th>
                                <th className="py-2 px-4 border-b">J/Reg</th>
                                <th className="py-2 px-4 border-b">Adresa</th>
                                <th className="py-2 px-4 border-b">Vezi documente atasate</th>
                            </tr>
                            </thead>
                            <tbody>
                            {providers.map((item, index) => (
                                <tr key={index} className="bg-white text-center">
                                    <td className="py-2 px-4 border-b">{item.name}</td>
                                    <td className="py-2 px-4 border-b">{item.cui}</td>
                                    <td className="py-2 px-4 border-b">{item.gei}</td>
                                    <td className="py-2 px-4 border-b">{item.address}</td>
                                    <td className="py-2 px-4 border-b flex justify-center">
                                        <Link
                                            to={location.pathname + ProviderRouter.viewProviderDetails}
                                            state={item}
                                            className="flex justify-center items-center gap-1 bg-accent-main-500 hover:bg-accent-main-700 text-white py-1 px-3 rounded"
                                        >
                                            Catre documente atasate <IoArrowForward/>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
            }
        </>
    );
}