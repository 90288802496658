import React from 'react';

interface JsonViewerProps {
    json: object;
}

const JsonViewer: React.FC<JsonViewerProps> = ({ json }) => {
    const renderJson = (obj: any, indentLevel: number = 0): JSX.Element[] => {
        const indent = ' '.repeat(indentLevel * 2);

        return Object.entries(obj ?? []).map(([key, value]) => (
            <div key={key} className="ml-4">
                <span className="text-accent-main-500">{indent}{key}:</span> {typeof value === 'object' ? (
                <div>
                    {renderJson(value, indentLevel + 1)}
                </div>
            ) : (
                <span className="text-gray-800">{value === null ? "null" : JSON.stringify(value)}</span>
            )}
            </div>
        ));
    };

    return (
        <div className="p-4 bg-gray-100 rounded shadow text-start">
            {renderJson(json)}
        </div>
    );
};

export default JsonViewer;
