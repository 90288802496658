import {Route, Routes} from "react-router-dom";
import ProviderRouter from "../../routers/ProviderRouter";
import ProvidersDashboard from "./components/ProvidersDashboard";
import React from "react";
import ViewProviderDetails from "./components/ViewProviderDetails";
import UserRoles from "../../commons/component/header/functionals/models/UserRoles";
import PrivateAccessRoute from "../../commons/functionals/authLogic/route_permitters/PrivateAccessRoute";

export default function ProvidersRootPage() {
    return (
        <Routes>
            <Route path={ProviderRouter.root}
                   element={
                       <PrivateAccessRoute
                           permittedApplicationAccess={"*"}
                           permittedRole={[UserRoles.ROLE_EMPLOYEE, UserRoles.ROLE_ACCOUNT_ADMIN]}>
                           <ProvidersDashboard/>
                       </PrivateAccessRoute>
                   }/>
            <Route path={ProviderRouter.viewProviderDetails}
                   element={
                       <PrivateAccessRoute
                           permittedApplicationAccess={"*"}
                           permittedRole={[UserRoles.ROLE_EMPLOYEE, UserRoles.ROLE_ACCOUNT_ADMIN]}>
                           <ViewProviderDetails/>
                       </PrivateAccessRoute>
                   }/>
        </Routes>
    );
}