import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../../commons/functionals/authLogic/AuthContext";
import AdminService from "../../../../services/AdminService";
import UserFirmDetails from "../../../../commons/component/header/functionals/models/UserFirmDetails";

export default function DashboardUsersPage() {

    const {user} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [users, setUsers] = useState<UserFirmDetails[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const adminService = new AdminService();

    useEffect(() => {
        if (user !== null)
            adminService.getUsers(currentPage).then((pageOfUser) => {
                setUsers(pageOfUser.content);
                setTotalPages(pageOfUser.totalPages);
            }).catch((_) => {
                setHasError(true);
            }).finally(() => setIsLoading(false));
    }, [user, currentPage]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return (
        <>
            <h1 className={"my-4 text-2xl font-semibold"}>All users from the apps</h1>
            {
                isLoading ?
                    <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                    </div> :
                    hasError ?
                        <div>err</div> :
                        users && users.length > 0 ? (
                            <>
                                <table className="min-w-full bg-white border border-gray-300">
                                    <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b">Username</th>
                                        <th className="py-2 px-4 border-b">Email</th>
                                        <th className="py-2 px-4 border-b">Role</th>
                                        <th className="py-2 px-4 border-b">Firm Name</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((user: UserFirmDetails) => (
                                        <tr key={user.user.slug}>
                                            <td className="py-2 px-4 border-b text-center">{user.user.username}</td>
                                            <td className="py-2 px-4 border-b text-center">{user.user.email}</td>
                                            <td className="py-2 px-4 border-b text-center">{user.user.role.name.replaceAll('_', ' ').replace('ROLE', '')}</td>
                                            <td className="py-2 px-4 border-b text-center">{user.firmName}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 0}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Previous
                                    </button>
                                    <span className="text-gray-600">
                                        Page {currentPage + 1} of {totalPages}
                                    </span>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages - 1}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="text-center text-gray-600">No users found.</div>
                        )
            }
        </>
    )
}