import axios from "axios";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import RegisterRequest from "../models/requests/RegisterRequest";

export default class RegisterService {

    async register(request: RegisterRequest): Promise<Response> {
        return await axios.post(apiRoutes.register, request, {withCredentials: true});
    }

}