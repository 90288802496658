import React, {FormEvent, useState} from "react";
import RegisterService from "../../services/RegisterService";
import Loading from "../../commons/component/loading/views/Loading";
import RegisterRequest from "../../models/requests/RegisterRequest";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import {IoIosArrowRoundForward} from "react-icons/io";
import {Link} from "react-router-dom";
import {IoArrowBack, IoArrowForward} from "react-icons/io5";
import {toast, Toaster} from "react-hot-toast";
import {FaEye, FaEyeSlash} from "react-icons/fa6";

export default function RegisterPage() {

    const counties = [
        "Alba", "Arad", "Argeș", "Bacău", "Bihor", "Bistrița-Năsăud", "Botoșani", "Brașov",
        "Brăila", "Buzău", "Caraș-Severin", "Călărași", "Cluj", "Constanța", "Covasna",
        "Dâmbovița", "Dolj", "Galați", "Giurgiu", "Gorj", "Harghita", "Hunedoara",
        "Ialomița", "Iași", "Ilfov", "Maramureș", "Mehedinți", "Mureș", "Neamț", "Olt",
        "Prahova", "Satu Mare", "Sălaj", "Sibiu", "Suceava", "Teleorman", "Timiș",
        "Tulcea", "Vaslui", "Vâlcea", "Vrancea", "București"
    ];
    const [request, setRequest] = useState<RegisterRequest>({
        email: '',
        password: '',
        username: '',
        firmName: '',
        cui: '',
        jReg: '',
        city: '',
        county: '',
        firmAddress: '',
        capitalSocial: '100',
        tipFirma: '',
        phoneNumber: '',
        vatPayer: '0',
        vatOnReceipt: '0'
    });
    const [error, setError] = useState<boolean | null>(null);
    const [componentLoading, setComponentLoading] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [firmType, setFirmType] = useState<string[]>([]);
    const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [passwordConfirm, setPasswordConfirm] = useState<string>("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [queryCounty, setQueryCounty] = useState<string>("");

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    const handleCheckboxChange = () => {
        setTermsAndConditionsAccepted(!termsAndConditionsAccepted);
    };


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setRequest((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleQueryCounty = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQueryCounty(e.target.value);
    }

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setTimeout(() => setIsFocused(false), 300);
    };

    const handleOptionClick = (county: string) => {
        setQueryCounty(county);
        setRequest((prevData) => ({
            ...prevData,
            county: county,
        }));
    };

    const checkFirstSlide = (): boolean => {
        if (request.cui.trim() === '' || !/^\d{10}$/.test(request.cui)) {
            toast.error("Completeaza corect CUI-ul");
            return false;
        }

        if (request.jReg.trim() === '' || !/^J\d{2}\/\d{3}\/\d{4}$/.test(request.jReg)) {
            toast.error("Numarul de inregistrare nu este conform");
            return false;
        }

        if (firmType.length === 0) {
            toast.error("Selecteaza tipul de firma");
            return false;
        }

        if (request.firmName.trim() === '' || request.firmName.trim().length <= 3) {
            toast.error("Completeaza numele firmei corect");
            return false;
        }

        return true;
    }

    const checkSecondSlide = (): boolean => {
        if (request.city.trim() === '') {
            toast.error("Completeaza numele localitatii / sectorului");
            return false;
        }

        if (request.county.trim() === '' || !counties.includes(request.county)) {
            toast.error("Selecteaza judetul");
            return false;
        }

        if (request.firmAddress.trim() === '') {
            toast.error("Completeaza cu adresa");
            return false;
        }

        return true;
    }

    const areFieldsEmpty = (request: RegisterRequest): boolean => {
        return Object.values(request).some(value => value.trim() === '');
    };

    const register = (event: FormEvent) => {
        event.preventDefault();
        request.tipFirma = firmType.join("|");
        request.username = firstName + " " + lastName;

        if (areFieldsEmpty(request)) {
            toast.error("Completeaza toate campurile!");
            return;
        }

        if (passwordConfirm !== request.password) {
            toast.error("Confirmarea parolei este diferita!");
            return;
        }

        if (!/^J\d{2}\/\d{3}\/\d{4}$/.test(request.jReg)) {
            toast.error("Numarul de inregistrare nu este conform");
            return;
        }

        if (!/^\d{10}$/.test(request.cui)) {
            toast.error("CUI-ul nu este conform");
            return;
        }

        setComponentLoading(true);
        new RegisterService().register(request).then((_) => {
            setError(false);
        }).catch((_) => {
            setError(true);
            toast.error("Am intalnit o eroare. Incearca din nou, putin mai tarziu!")
        }).finally(() => setComponentLoading(false));
    }

    const handleFirmTypeUpdate = (firmTypeSlug: string) => {
        let arr: string[] = [];
        switch (firmTypeSlug) {
            case 'expedition':
                arr = firmType.includes('expedition') ? [] : ['expedition'];
                if (firmType.includes('transportator')) arr.push('transportator');
                break;
            case 'transport':
                arr = firmType.includes('transport') ? [] : ['transport'];
                break;
            case 'transportator':
                arr = firmType.includes('transportator') ? [] : ['transportator'];
                if (firmType.includes('expedition')) arr.push('expedition');
                break;
        }
        setFirmType(arr);
    }

    const buttonState = (buttonNumber: number) => {
        switch (buttonNumber) {
            case 1:
                return page === 1 ?
                    <>Catre adresa firmei <IoArrowForward/></> :
                    page === 2 ?
                        <><IoArrowBack/> Catre detalii firma</> :
                        <></>
            case 2:
                return page === 1 ? <></> :
                    page === 2 ?
                        <>Catre detalii cont <IoArrowForward/></> :
                        <><IoArrowBack/> Inapoi la adresa firmei</>
            default:
                return <></>
        }
    }

    return (
        <div className={"h-screen w-screen flex justify-center items-center"}>
            <div><Toaster/></div>
            {
                error === false ?
                    <div className={"text-3xl text-center text-green-700"}>
                        <p>Inregistrare cu success</p>
                        <p>Te rugam sa accesezi linkul din emailul trimis pentru confirma contul!</p>
                    </div> :
                    <div className={"bg-white shadow-lg rounded-xl w-full md:w-2/3 lg:w-1/3 p-10"}>
                        <div className="mb-6">
                            <form onSubmit={register} className={"flex justify-center items-align flex-col"}>
                                {
                                    page === 1 && (<>
                                        <h1 className={"pb-4 text-3xl text-center font-main-bold"}>
                                            Cateva detalii despre firma ta:
                                        </h1>
                                        <label className={"mt-3 text-sm"}>Tipul Firmei</label>
                                        <div className={"flex flex-row pt-2 gap-2"}>
                                            <div
                                                onClick={() => handleFirmTypeUpdate("expedition")}
                                                className={`cursor-pointer font-semibold flex justify-center items-center text-center w-full p-4 rounded-lg ${firmType.includes("expedition") ? "bg-accent-main-500 text-white" : "bg-gray-50"}`}>
                                                Companie
                                                expeditie
                                            </div>
                                            <div
                                                onClick={() => handleFirmTypeUpdate("transport")}
                                                className={`cursor-pointer font-semibold flex justify-center items-center text-center w-full p-4 rounded-lg ${firmType.includes("transport") ? "bg-accent-main-500 text-white" : "bg-gray-50"}`}>
                                                Client
                                                transport
                                            </div>
                                            <div
                                                onClick={() => handleFirmTypeUpdate("transportator")}
                                                className={`cursor-pointer font-semibold flex justify-center items-center text-center w-full p-4 rounded-lg ${firmType.includes("transportator") ? "bg-accent-main-500 text-white" : "bg-gray-50"}`}>
                                                Transportator
                                            </div>
                                        </div>
                                        <label className={"mt-3 text-sm"}>Denumirea Firmei</label>
                                        <input type="text"
                                               required={true}
                                               name="firmName"
                                               placeholder={"Completeaza cu SRL la final"}
                                               value={request.firmName}
                                               onChange={handleInputChange}
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <label className={"mt-3 text-sm"}>CUI</label>
                                        <input type="text"
                                               required={true}
                                               pattern="^[0-9]{2,10}$"
                                               name="cui"
                                               value={request.cui}
                                               onChange={(e) => {
                                                   const value = e.target.value;
                                                   if (/^\d{0,10}$/.test(value)) {
                                                       handleInputChange(e);
                                                   }
                                               }}
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                        <label className={"mt-3 text-sm"}>Reg Comert</label>
                                        <input type="text"
                                               required={true}
                                               name="jReg"
                                               value={request.jReg}
                                               onChange={handleInputChange}
                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                    </>)
                                }
                                {
                                    page === 2 && (
                                        <>
                                            <h1 className={"pb-4 text-3xl text-center font-main-bold"}>
                                                Adresa firmei tale:
                                            </h1>
                                            <div className={"flex flex-row gap-2"}>
                                                <div className={"mt-3 w-full"}>
                                                    <label className={"text-sm"}>Localitate/Sector</label>
                                                    <input
                                                        type="text"
                                                        required={true}
                                                        name="city"
                                                        value={request.city}
                                                        onChange={handleInputChange}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                                </div>
                                                <div className="relative mt-3 w-full">
                                                    <label className="text-sm">Județ</label>
                                                    <input
                                                        type="text"
                                                        value={queryCounty}
                                                        onFocus={handleFocus}
                                                        onBlur={handleBlur}
                                                        onChange={handleQueryCounty}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"
                                                        placeholder="Selectează județul"
                                                    />
                                                    {(isFocused &&
                                                        <ul className="absolute z-10 w-full mt-1 h-fit max-h-[200px] overflow-x-auto bg-white border border-gray-300 rounded-lg shadow-lg">
                                                            {counties.filter((value) => value.toLowerCase().includes(queryCounty.toLowerCase())).length === 0 ? (
                                                                <li className="p-2 text-gray-500">No results</li>
                                                            ) : (
                                                                counties.filter((value) => value.toLowerCase().includes(queryCounty.toLowerCase())).map(county => (
                                                                    <li
                                                                        key={county}
                                                                        onClick={() => handleOptionClick(county)}
                                                                        className="cursor-pointer p-2 hover:bg-gray-100"
                                                                    >
                                                                        {county}
                                                                    </li>
                                                                ))
                                                            )}
                                                        </ul>)}
                                                </div>
                                            </div>
                                            <label className={"mt-3 text-sm"}>Adresa</label>
                                            <input type="text"
                                                   required={true}
                                                   name="firmAddress"
                                                   value={request.firmAddress}
                                                   onChange={handleInputChange}
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                            <div className={"flex flex-row mt-5"}>
                                                <div className="w-full">
                                                    <span className="block text-sm mb-1">TVA la încasare?</span>
                                                    <label className="inline-flex items-center mr-4">
                                                        <input
                                                            type="radio"
                                                            name="vatOnReceipt"
                                                            value="1"
                                                            className="form-radio h-5 w-5 text-accent-main-500"
                                                            checked={request.vatOnReceipt === "1"}
                                                            onChange={handleInputChange}
                                                        />
                                                        <span className="ml-2">Da</span>
                                                    </label>
                                                    <label className="inline-flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="vatOnReceipt"
                                                            value="0"
                                                            className="form-radio h-5 w-5 text-accent-main-500"
                                                            checked={request.vatOnReceipt === "0"}
                                                            onChange={handleInputChange}
                                                        />
                                                        <span className="ml-2">Nu</span>
                                                    </label>
                                                </div>
                                                <div className="w-full">
                                                    <span className="block text-sm mb-1">Platitor de TVA?</span>
                                                    <label className="inline-flex items-center mr-4">
                                                        <input
                                                            type="radio"
                                                            name="vatPayer"
                                                            value="1"
                                                            className="form-radio h-5 w-5 text-accent-main-500"
                                                            checked={request.vatPayer === "1"}
                                                            onChange={handleInputChange}
                                                        />
                                                        <span className="ml-2">Da</span>
                                                    </label>
                                                    <label className="inline-flex items-center">
                                                        <input
                                                            type="radio"
                                                            name="vatPayer"
                                                            value="0"
                                                            className="form-radio h-5 w-5 text-accent-main-500"
                                                            checked={request.vatPayer === "0"}
                                                            onChange={handleInputChange}
                                                        />
                                                        <span className="ml-2">Nu</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </>)
                                }
                                {
                                    page === 3 && (
                                        <>
                                            <h1 className={"pb-4 text-3xl text-center font-main-bold"}>
                                                Contul este gata, spune-ne despre tine
                                            </h1>
                                            <div className={"flex flex-row gap-2"}>
                                                <div className={"w-full"}>
                                                    <label className={"mt-3 text-sm"}>Prenume</label>
                                                    <input type="text"
                                                           required={true}
                                                           value={firstName}
                                                           onChange={(e) => setFirstName(e.target.value)}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>

                                                </div>
                                                <div className={"w-full"}>
                                                    <label className={"mt-3 text-sm"}>Nume</label>
                                                    <input type="text"
                                                           required={true}
                                                           value={lastName}
                                                           onChange={(e) => setLastName(e.target.value)}
                                                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>

                                                </div>
                                            </div>
                                            <label className={"mt-3 text-sm"}>Email</label>
                                            <input type="text"
                                                   required={true}
                                                   name="email"
                                                   value={request.email}
                                                   onChange={handleInputChange}
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                            <div className="mt-3">
                                                <label className={"mt-3 text-sm"}>Parola</label>
                                                <div className="relative mb-1">
                                                    <input
                                                        type={isPasswordVisible ? 'text' : 'password'}
                                                        required={true}
                                                        name="password"
                                                        value={request.password}
                                                        onChange={handleInputChange}
                                                        className="mb-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                                    <button
                                                        type="button"
                                                        onClick={togglePasswordVisibility}
                                                        className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                                                    >
                                                        {isPasswordVisible ? <FaEyeSlash/> : <FaEye/>}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <label className="text-sm">Confirma parola</label>
                                                <div className="relative mb-1">
                                                    <input
                                                        type={isPasswordVisible ? 'text' : 'password'}
                                                        required={true}
                                                        value={passwordConfirm}
                                                        onChange={(e) => setPasswordConfirm(e.target.value)}
                                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500 pr-10"
                                                    />
                                                    <button
                                                        type="button"
                                                        onClick={togglePasswordVisibility}
                                                        className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                                                    >
                                                        {isPasswordVisible ? <FaEyeSlash/> : <FaEye/>}
                                                    </button>
                                                </div>
                                            </div>
                                            <label className={"mt-3 text-sm"}>Numar de telefon</label>
                                            <input type="text"
                                                   required={true}
                                                   name="phoneNumber"
                                                   value={request.phoneNumber}
                                                   onChange={handleInputChange}
                                                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 outline-none focus:ring-accent-main-500 focus:border-accent-main-500"/>
                                            <div className="mt-5">
                                                <label className="inline-flex items-center">
                                                    <input
                                                        type="checkbox"
                                                        className="form-checkbox h-5 w-5 text-accent-main-500"
                                                        checked={termsAndConditionsAccepted}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <span className="ml-2 text-gray-700">
                                                        Am luat la cunoștință de
                                                        <Link to="https://inovia-trans.ro/terms-and-conditions"
                                                              target="_blank"
                                                              rel="noopener noreferrer"
                                                              className="text-accent-main-500 hover:text-accent-main-800 mx-1">
                                                            Termenii si conditiile de utilizare
                                                        </Link>
                                                         a Inovia-Trans
                                                    </span>
                                                </label>
                                            </div>
                                        </>)}
                                <div className={"flex flex-row"}>
                                    <div
                                        onClick={() => {
                                            if (page === 1) {
                                                if (checkFirstSlide()) setPage(2);
                                            } else
                                                setPage(1);
                                        }}
                                        className={`${page !== 3 ? "w-full px-5 py-2.5" : "w-0"} ${page === 2 ? "mr-2" : ""} h-[40px] cursor-pointer text-white bg-accent-main-500 font-main-medium rounded-lg text-sm text-center gap-2 transition-all flex justify-center items-center mt-5`}>
                                        {buttonState(1)}
                                    </div>
                                    <div
                                        onClick={() => {
                                            if (page === 2) {
                                                if (checkSecondSlide()) setPage(3);
                                            } else setPage(2);
                                        }}
                                        className={`${page !== 1 ? "w-full px-5 py-2.5" : "w-0"} ${page === 3 ? "mr-2" : ""} h-[40px] cursor-pointer text-white bg-accent-main-500 font-main-medium rounded-lg text-sm text-center gap-2 transition-all flex justify-center items-center mt-5`}>
                                        {buttonState(2)}
                                    </div>
                                    <button type="submit"
                                            disabled={componentLoading || !termsAndConditionsAccepted}
                                            className={`${page === 3 ? "w-full px-5 py-2.5" : "w-0"} h-[40px] text-white bg-accent-main-500 disabled:bg-gray-300 font-main-medium rounded-lg text-sm text-center transition-all flex justify-center items-center mt-5`}>
                                        {page !== 3 ? "" : (!componentLoading ? "Inregistreaza-te" : <Loading/>)}
                                    </button>
                                </div>
                                <Link to={ApplicationRoutes.login}
                                      className={"mt-2 flex text-sm justify-center items-center w-full text-center"}>
                                    <span className={"mr-1"}>Ai un cont?</span>
                                    <IoIosArrowRoundForward/>
                                </Link>
                            </form>
                        </div>
                    </div>
            }
        </div>
    );
}