import React from "react";
import {Link, useLocation} from "react-router-dom";
import FirmProviderDetails from "../../../models/FirmProviderDetails";
import {IoArrowForward} from "react-icons/io5";
import AiDocumentCheckerRouter from "../../../routers/AiDocumentCheckerRouter";
import DashboardUserRouter from "../../../routers/DashboardUserRouter";
import {Toaster} from "react-hot-toast";
import {PiIdentificationBadgeFill} from "react-icons/pi";
import {FaBookOpen} from "react-icons/fa";
import {FaLocationDot} from "react-icons/fa6";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";

export default function ViewProviderDetails() {

    const location = useLocation();
    const parsedParams = location.state as FirmProviderDetails;

    return (
        <>
            <div><Toaster/></div>
            <div className="overflow-x-auto">
                <h1 className={"font-main-medium text-2xl"}>Documentele furnizorului {parsedParams.name}</h1>
                <div className={"mb-4 text-sm text-gray-600 flex gap-3 flex-row items-center"}>
                    <p className={"flex gap-1 flex-row items-center"}><PiIdentificationBadgeFill/> {parsedParams.cui ?? "-"}</p>
                    <p className={"flex gap-1 flex-row items-center"}><FaBookOpen/> {parsedParams.gei ?? "-"}</p>
                    <p className={"flex gap-1 flex-row items-center"}><FaLocationDot/> {parsedParams.address ?? "-"}</p>
                </div>
                <table className="min-w-full bg-accent-main-500 rounded-xl">
                    <thead>
                    <tr className="text-white">
                        <th className="py-2 px-4 border-b">Numele documentului</th>
                        <th className="py-2 px-4 border-b">Tipul documentului</th>
                        <th className="py-2 px-4 border-b">Vezi analiza documentului atasate</th>
                    </tr>
                    </thead>
                    <tbody>
                    {parsedParams.documentsLinked.map((item, index) => (
                        <tr key={index} className="bg-white">
                            <td className="py-2 px-4 border-b">{item.first}</td>
                            <td className="py-2 px-4 border-b">{item.second}</td>
                            <td className="py-2 px-4 border-b flex justify-center">
                                <Link
                                    to={ApplicationRoutes.dashboard + DashboardUserRouter.AiDocumentChecker + AiDocumentCheckerRouter.viewTaskEntrance + item.additionalParam}
                                    className="flex justify-center items-center gap-1 bg-accent-main-500 hover:bg-accent-main-700 text-white font-bold py-1 px-3 rounded"
                                >
                                    Vezi detalii document <IoArrowForward/>
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}