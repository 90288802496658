import {DocumentTypeEnhanced} from "../../../models/DocumentTypeEnhanced";

export default class Utils {
    static convertToText(documentType: DocumentTypeEnhanced): string {
        switch (documentType) {
            case DocumentTypeEnhanced.GENERAL:
                return "General";
            case DocumentTypeEnhanced.CI:
                return "Carte de identitate";
            case DocumentTypeEnhanced.ANEXES:
                return "Anexa";
            case DocumentTypeEnhanced.EXPEDITION_INSURANCE:
                return "Asigurare de expeditie";
            case DocumentTypeEnhanced.TRANSPORTATION_LICENCE:
                return "Licenta de transport";
            case DocumentTypeEnhanced.CMR_INSURANCE:
                return "Asigurare CMR";
            case DocumentTypeEnhanced.CUI:
                return "CUI";
            case DocumentTypeEnhanced.EXPEDITION_LICENCE:
                return "Licenta de expeditie";
            case DocumentTypeEnhanced.PAYMENT_PROOF:
                return "Dovada plata";
            case DocumentTypeEnhanced.TALON:
                return "Talon";
            case DocumentTypeEnhanced.TRANSPORTATION_CONFORM_COPY:
                return "Copie confirma de transport";
        }
    }
}