import Firm from "../../../models/Firm";
import Loading from "../../../commons/component/loading/views/Loading";
import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import FirmService from "../../../services/FirmService";

export default function FirmDetailsPage() {
    const [firm, setFirm] = useState<Firm | null>(null);
    const {user} = useContext(AuthContext);

    useEffect(() => {
        if (user === null)
            return;

        new FirmService().getFirmDetails(user.firmSlug).then((response) => {
            setFirm(response);
        }).catch(err => {
            console.log(err);
        });
    }, [user]);

    return (
        <>
            {firm === null ? (
                <Loading color={"fill-accent-main-500"}/>
            ) : (
                <div>
                    <h2 className="text-2xl font-main-bold mb-4">Firm Details</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        <div className="bg-white p-4 rounded-md shadow-md">
                            <h3 className="text-lg font-main-medium mb-2">Name</h3>
                            <p>{firm?.name ?? 'Nothing here'}</p>
                        </div>

                        <div className="bg-white p-4 rounded-md shadow-md">
                            <h3 className="text-lg font-main-medium mb-2">Unique ID</h3>
                            <p>{firm?.slug ?? 'Nothing here'}</p>
                        </div>

                        <div className="bg-white p-4 rounded-md shadow-md">
                            <h3 className="text-lg font-main-medium mb-2">CUI</h3>
                            <p>{firm?.cui ?? 'Nothing here'}</p>
                        </div>

                        <div className="bg-white p-4 rounded-md shadow-md">
                            <h3 className="text-lg font-main-medium mb-2">Address</h3>
                            <p>{firm?.address ?? 'Nothing here'}</p>
                        </div>
                    </div>

                    <h2 className="text-2xl font-bold mt-10 mb-4">Detalii administrator</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                        <div className="bg-white p-4 rounded-md shadow-md">
                            <h3 className="text-lg font-main-medium mb-2">Nume</h3>
                            <p>{firm?.firmAdmin.username ?? 'Eroare'}</p>
                        </div>

                        <div className="bg-white p-4 rounded-md shadow-md">
                            <h3 className="text-lg font-main-medium mb-2">Adresa de email</h3>
                            <p>{firm?.firmAdmin.email ?? 'Eroare'}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
