import {AxiosResponse} from "axios";
import {apiRoutes} from "../../../../environment/EnvironmentRoutes";
import UserResponse from "../models/UserResponse";
import axiosInstance from "../../../../functionals/authLogic/AxiosInterceptors";

export default class HeaderService {

    async getUserDetails(): Promise<UserResponse> {
        const response: AxiosResponse<UserResponse> = await axiosInstance.get<UserResponse>(apiRoutes.user);
        return response.data;
    }

    async logoutUser(): Promise<UserResponse> {
        const response: AxiosResponse<UserResponse> = await axiosInstance.post<UserResponse>(apiRoutes.logout);
        return response.data;
    }
}