import {AxiosResponse} from "axios";
import axiosInstance from "../commons/functionals/authLogic/AxiosInterceptors";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import {Page} from "../commons/functionals/general_models/Page";
import Firm from "../models/Firm";
import UserFirmDetails from "../commons/component/header/functionals/models/UserFirmDetails";
import FirmActions from "../models/responses/FirmActions";

export default class AdminService {

    async getPendingUsers(page: number | null): Promise<Page<UserFirmDetails>> {
        const response: AxiosResponse<Page<UserFirmDetails>> = await axiosInstance.get<Page<UserFirmDetails>>(apiRoutes.usersPending + "?page=" + (page ?? 0));
        return response.data;
    }

    async acceptUser(slug: string): Promise<void> {
        const response: AxiosResponse<void> = await axiosInstance.post<void>(apiRoutes.usersAccept.replace("SLUG", slug));
        return response.data;
    }

    async rejectUser(slug: string): Promise<void> {
        const response: AxiosResponse<void> = await axiosInstance.post<void>(apiRoutes.usersRefuse.replace("SLUG", slug));
        return response.data;
    }

    async getUsers(page: number | null): Promise<Page<UserFirmDetails>> {
        const response: AxiosResponse<Page<UserFirmDetails>> = await axiosInstance.get<Page<UserFirmDetails>>(apiRoutes.users + "?page=" + (page ?? 0));
        return response.data;
    }

    async getFirms(page: number | null): Promise<Page<Firm>> {
        const response: AxiosResponse<Page<Firm>> = await axiosInstance.get<Page<Firm>>(apiRoutes.firms + "?page=" + (page ?? 0));
        return response.data;
    }

    async getFirmsActions(page: number | null): Promise<Page<FirmActions>> {
        const response: AxiosResponse<Page<FirmActions>> = await axiosInstance.get<Page<FirmActions>>(apiRoutes.firmsActions + "?page=" + (page ?? 0));
        return response.data;
    }

    async acceptPayment(uniqueIdentifier: string) {
        const response: AxiosResponse<string> = await axiosInstance.post<string>(apiRoutes.subscriptionsCheckoutSuccess + "/" + uniqueIdentifier);
        return response.data;
    }
}