import SubscriptionStatisticsType from "../../../models/SubscriptionStatisticsType";

export default class Generals {

    static getSystemStatusDescription(status: SubscriptionStatisticsType): string {
        let subscriptionDescriptions: Record<SubscriptionStatisticsType, string> = {
            [SubscriptionStatisticsType.creditsUsage]: "Credite ramase",
            [SubscriptionStatisticsType.memoryUsage]: "Memorie folosita",
            [SubscriptionStatisticsType.usersUsage]: "Numar de utilizatori"
        };

        return subscriptionDescriptions[status];
    }

}