import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import Loading from "../../commons/component/loading/views/Loading";

const RedirectToLoginPage = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const location = useLocation();

    useEffect(() => {
        if (isLoading)
            setTimeout(() => setIsLoading(false), 3000);
    }, [isLoading]);

    return (
        <div className={"h-screen w-full flex justify-center items-center"}>
            {
                isLoading ?
                    <Loading color={"fill-accent-main-500"}/> :
                    <div
                        className="m-8 p-5 flex flex-col items-center justify-center bg-white rounded-xl shadow-lg w-[400px]">
                        <p className={"text-xl mb-5"}>Nu esti logat</p>
                        <Link to={ApplicationRoutes.login + "?next=" + location.pathname}
                              className={"text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center w-full"}>
                            Catre logare
                        </Link>
                    </div>
            }
        </div>
    );
}

export default RedirectToLoginPage;