import React, {useContext} from "react";
import {AuthContext} from "../../../../commons/functionals/authLogic/AuthContext";

export default function DashboardPage() {

    const {user} = useContext(AuthContext);

    return (
        <>
            <div className="p-4 w-full">
                <h1 className={"pt-8 px-8 text-4xl font-main-bold"}>Bine ai venit, {user?.username}!</h1>
            </div>
        </>
    )
}