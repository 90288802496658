import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext} from "../../commons/functionals/authLogic/AuthContext";
import {toast, Toaster} from "react-hot-toast";
import FirmService from "../../services/FirmService";
import Loading from "../../commons/component/loading/views/Loading";
import ActionOrderType from "../../models/requests/ActionOrderType";
import ApplicationRoutes from "../../commons/environment/AppRoutingName";
import DashboardUserRouter from "../../routers/DashboardUserRouter";
import FirmSettingsRouter from "../../routers/FirmSettingsRouter";
import {MdOutlineRequestPage} from "react-icons/md";
import {IoMdPricetag} from "react-icons/io";
import {FaMinus, FaPlus, FaRocket} from "react-icons/fa";
import {PiCrownFill} from "react-icons/pi";
import {IoBusiness} from "react-icons/io5";
import {
    AiDocumentCheckerCreditsDetailsServer,
    AiDocumentCheckerSubscriptionDetailsServer,
    SubscriptionOrderItem
} from "../../models/responses/SubscriptionOrderItem";
import {
    AiDocumentCheckerSubscriptionDetails,
    MarketOrderItem,
    MarketOrderRequest
} from "../../models/requests/MarketOrderRequest";

const StoreScreen: React.FC = () => {
    const {user} = useContext(AuthContext);
    const navigate = useNavigate();
    const [cost, setCost] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [orderData, setOrderData] = useState<SubscriptionOrderItem[]>([]);
    const [isLoadingComponent, setIsLoadingComponent] = useState<boolean>(true);

    const [numberOfUsers, setNumberOfUsers] = useState<number>(4);
    const [selectedAiDocumentCheckerSubscriptionObject, setSelectedAiDocumentCheckerSubscriptionObject] = useState<AiDocumentCheckerSubscriptionDetailsServer | null>(null);
    const [selectedAiDocumentCheckerCreditsObject, setSelectedAiDocumentCheckerCreditsObject] = useState<AiDocumentCheckerCreditsDetailsServer | null>(null);

    const [paymentMethod, setPaymentMethod] = useState<string>("bankTransfer");
    const [order, setOrder] = useState<MarketOrderRequest>({items: [], paymentMethod: "bankTransfer"});
    const service = new FirmService();

    useEffect(() => {
        setIsLoadingComponent(true);
        service.getDataForMarketScreen()
            .then((res) => setOrderData(res))
            .catch((_) => toast.error("Am intampinat o eroare! Incearca din nou"))
            .finally(() => setIsLoadingComponent(false));
    }, []);

    const incrementUsers = () => setNumberOfUsers(prev => prev + 1);
    const decrementUsers = () => setNumberOfUsers(prev => Math.max(4, prev - 1));

    const buy = () => {
        if (cost === 0) {
            toast.error("Adauga produse in cos pentru a cumpara!");
            return;
        }

        setIsLoading(true);
        service.marketOrder(user?.firmSlug ?? "none", {items: order.items, paymentMethod: paymentMethod})
            .then((response) => {
                setSelectedAiDocumentCheckerSubscriptionObject(null);
                setSelectedAiDocumentCheckerCreditsObject(null);
                setOrder({items: [], paymentMethod: paymentMethod});
                if (response.message != null) {
                    toast.error(response.message);
                } else {
                    navigate(ApplicationRoutes.payment, {state: response.linkToSend === "no_link" ? null : response});
                }
            })
            .catch((_) => {
                toast.error("Am intampinat o eroare!");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const addToOrder = (orderItem: MarketOrderItem) => {
        setOrder({items: [...order.items, orderItem], paymentMethod: paymentMethod});
    }

    const removeToOrder = (slug: string) => {
        setOrder({items: order.items.filter((v) => v.slug !== slug), paymentMethod: paymentMethod});
    }

    useEffect(() => {
        if (orderData.length === 0)
            return;

        let finalCosts = 0;
        order.items.forEach((item) => {
            switch (item.slug) {
                case orderData[0].slug:
                    finalCosts += selectedAiDocumentCheckerSubscriptionObject!.totalCost +
                        selectedAiDocumentCheckerSubscriptionObject!.perUserMore * ((item.payload.details as AiDocumentCheckerSubscriptionDetails).numberOfUsers - selectedAiDocumentCheckerSubscriptionObject!.baseUsers)
                    break;

                case orderData[1].slug:
                    if (selectedAiDocumentCheckerCreditsObject !== null) {
                        finalCosts += selectedAiDocumentCheckerCreditsObject.creditPrice;
                    }
                    break;
            }
        });
        setCost(finalCosts);
    }, [order, numberOfUsers, selectedAiDocumentCheckerSubscriptionObject, selectedAiDocumentCheckerCreditsObject]);

    return (
        <>
            <div><Toaster/></div>
            <div className={"h-[calc(100vh-150px)] overflow-y-auto w-full"}>
                {isLoadingComponent ? <Loading/> :
                    <div className="flex flex-col gap-10 h-fit w-full">
                        {
                            !(user?.allowedApplications ?? []).includes(process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none') ?
                                <div className="flex gap-10 justify-between pl-4">
                                    <div className={"w-full"}>
                                        <h1 className="text-3xl font-main-medium">
                                            Cumpara abonament Document Checker:
                                        </h1>
                                        <div className="flex flex-row gap-2 w-full py-4">
                                            {
                                                orderData[0].detailsList.map((data, i) =>
                                                    <div key={i}
                                                         onClick={() => setSelectedAiDocumentCheckerSubscriptionObject(data as AiDocumentCheckerSubscriptionDetailsServer)}
                                                         className={`max-w-[260px] p-4 text-center w-full cursor-pointer rounded-lg shadow-md border-2 ${data.slug === selectedAiDocumentCheckerSubscriptionObject?.slug ? "border-accent-main-500" : "border-white"}`}>
                                                        <div
                                                            className="text-accent-main-500 font-main-bold mb-2 text-4xl flex items-center justify-center gap-2">
                                                            <div className={"text-3xl flex items-center"}>
                                                                {
                                                                    i === 0 ? <IoMdPricetag/> :
                                                                        i === 1 ? <FaRocket/> :
                                                                            i === 2 ? <PiCrownFill/> :
                                                                                <IoBusiness/>
                                                                }
                                                            </div>
                                                            {(data as AiDocumentCheckerSubscriptionDetailsServer).displayName}
                                                        </div>
                                                        <p className={"flex gap-1 items-center justify-center mr-4"}>
                                                            <MdOutlineRequestPage/> {(data as AiDocumentCheckerSubscriptionDetailsServer).totalScans} credite
                                                        </p>

                                                        <div className={"px-4"}>
                                                            <label className="text-lg mb-2 font-main-medium"
                                                                   htmlFor="numberOfUsers">
                                                                Numarul de utilizatori:
                                                            </label>
                                                            <div
                                                                className="text-center p-2 border-2 border-accent-main-500 rounded-lg"
                                                            >
                                                                {
                                                                    numberOfUsers
                                                                }
                                                            </div>
                                                            <div className="flex flex-row justify-between pt-2">
                                                                <button
                                                                    disabled={order.items.filter((el) => el.slug === orderData[0].slug).length !== 0}
                                                                    onClick={incrementUsers}
                                                                    className="flex items-center justify-center bg-accent-main-500 text-white text-2xl py-3 px-3 rounded-full disabled:bg-gray-400"
                                                                >
                                                                    <FaPlus/>
                                                                </button>
                                                                <button
                                                                    disabled={order.items.filter((el) => el.slug === orderData[0].slug).length !== 0}
                                                                    onClick={decrementUsers}
                                                                    className="flex items-center justify-center bg-accent-main-500 text-white text-2xl py-3 px-3 rounded-full disabled:bg-gray-400"
                                                                >
                                                                    <FaMinus/>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <p className={"text-center text-2xl font-main-medium mt-5"}>
                                                            {(data as AiDocumentCheckerSubscriptionDetailsServer).totalCost} €
                                                            <span className={"text-sm"}>+ TVA</span>
                                                        </p>
                                                        <p className={"text-gray-400 text-sm"}>
                                                            * un credit reprezinta o pagina
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className={"flex justify-end w-52"}>
                                        {
                                            order.items.filter((v) => v.slug === orderData[0].slug).length === 0 ?
                                                <button
                                                    className={"bg-accent-main-500 h-fit p-4 rounded-lg text-white disabled:bg-gray-400"}
                                                    onClick={() => addToOrder({
                                                        slug: orderData[0].slug,
                                                        actionType: ActionOrderType.buyAiDocumentCheckerSubscription,
                                                        payload: {
                                                            appSlug: process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? '',
                                                            details: {
                                                                selectedSubscriptionSlug: selectedAiDocumentCheckerSubscriptionObject!.slug,
                                                                numberOfUsers: numberOfUsers,
                                                            }
                                                        }
                                                    })}
                                                    disabled={selectedAiDocumentCheckerSubscriptionObject === null}
                                                >
                                                    Adauga in cos
                                                </button>
                                                :
                                                <button className={"bg-error p-4 h-fit rounded-lg text-white"}
                                                        onClick={() => removeToOrder(orderData[0].slug)}
                                                >
                                                    Renunta la produs
                                                </button>
                                        }
                                    </div>
                                </div>
                                :
                                <div className={"flex gap-2 px-4"}>
                                    <span>Pentru Document Checker ai deja un abonament activ. </span>
                                    <Link
                                        to={ApplicationRoutes.dashboard + DashboardUserRouter.firmDetails + FirmSettingsRouter.subscription}
                                        className={"text-accent-main-500 font-main-medium"}>
                                        Il poti vizualiza aici
                                    </Link>
                                </div>
                        }
                        {
                            !(user?.allowedApplications ?? []).includes(process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none') ?
                                <div className={"flex gap-2 px-4"}>
                                    <span>Cumpara un abonament pentru Document Checker, daca doresti sa achizitionezi credite </span>
                                </div>
                                :
                                <div className="flex gap-10 justify-between pl-4">
                                    <div className={"w-full"}>
                                        <h1 className="text-3xl mb-2 font-main-medium">
                                            Cumpara credite pentru Document Checker:
                                        </h1>
                                        <div className="flex flex-row gap-4 py-4">
                                            {
                                                orderData[1].detailsList.map((data, i) =>
                                                    <div key={i}
                                                         onClick={() => setSelectedAiDocumentCheckerCreditsObject(data as AiDocumentCheckerCreditsDetailsServer)}
                                                         className={`p-4 text-center w-full cursor-pointer rounded-lg shadow-md border-2 ${data.slug === selectedAiDocumentCheckerCreditsObject?.slug ? "border-accent-main-500" : "border-white"}`}>
                                                        <div
                                                            className="text-accent-main-500 font-main-bold mb-2 text-4xl flex items-center justify-center gap-2">
                                                            <div className={"text-3xl flex items-center"}>
                                                                {
                                                                    i === 0 ? <IoMdPricetag/> :
                                                                        i === 1 ? <FaRocket/> :
                                                                            i === 2 ? <PiCrownFill/> :
                                                                                <IoBusiness/>
                                                                }
                                                            </div>
                                                            {(data as AiDocumentCheckerCreditsDetailsServer).packageDisplayName}
                                                        </div>
                                                        <p className={"flex gap-1 items-center justify-center mr-4"}>
                                                            <MdOutlineRequestPage/> {(data as AiDocumentCheckerCreditsDetailsServer).creditAmount} credite
                                                        </p>
                                                        <p className={"text-center text-2xl font-main-medium mt-5"}>
                                                            {(data as AiDocumentCheckerCreditsDetailsServer).creditPrice} €
                                                            <span className={"text-sm"}>+ TVA</span>
                                                        </p>
                                                        <p className={"text-gray-400 text-sm"}>
                                                            * un credit reprezinta o pagina
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className={"flex justify-end w-52"}>
                                        {
                                            order.items.filter((v) => v.slug === orderData[1].slug).length === 0 ?
                                                <button
                                                    className={"bg-accent-main-500 h-fit p-4 rounded-lg text-white disabled:bg-gray-400"}
                                                    onClick={() => addToOrder({
                                                        slug: orderData[1].slug,
                                                        actionType: ActionOrderType.buyAiDocumentCheckerCredits,
                                                        payload: {
                                                            appSlug: process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? '',
                                                            details: {
                                                                packageToBuy: selectedAiDocumentCheckerCreditsObject!.slug
                                                            }
                                                        }
                                                    })}
                                                    disabled={selectedAiDocumentCheckerCreditsObject === null}
                                                >
                                                    Adauga in cos
                                                </button>
                                                :
                                                <button className={"bg-error p-4 rounded-lg h-fit text-white"}
                                                        onClick={() => removeToOrder(orderData[1].slug)}
                                                >
                                                    Renunta la produs
                                                </button>
                                        }
                                    </div>
                                </div>
                        }
                    </div>
                }
            </div>
            <div className="flex h-[50px] items-center justify-end gap-5">
                <div
                    className={"flex items-center gap-2 bg-accent-main-500 text-white font-semibold rounded-lg py-1.5 p-3"}>
                    <span>
                        Modalitate de plata:
                    </span>
                    <div className={"flex gap-2 border border-white rounded-xl"}>
                        {/*<div onClick={() => setPaymentMethod("card")}*/}
                        {/*     className={"cursor-pointer px-3 py-1.5 rounded-lg " + (paymentMethod === "card" ? "bg-white text-black shadow-lg" : "bg-transparent")}>*/}
                        {/*    Card*/}
                        {/*</div>*/}
                        <div onClick={() => setPaymentMethod("bankTransfer")}
                             className={"cursor-pointer px-3 py-1.5 rounded-lg " + (paymentMethod === "bankTransfer" ? "bg-white text-black shadow-lg" : "bg-transparent")}>
                            Transfer bancar
                        </div>
                    </div>
                </div>
                <div className={"flex items-center gap-2 bg-accent-main-500 text-white font-semibold rounded-lg p-3"}>
                    <span>{cost} €</span>
                </div>
                <button
                    onClick={buy}
                    className="hover:bg-accent-main-600 w-48 py-3 bg-accent-main-500 text-white font-semibold rounded-lg disabled:opacity-50 flex justify-center items-center"
                    disabled={isLoading}
                >
                    {isLoading ? <Loading/> : "Cumpara"}
                </button>
            </div>
        </>
    )
        ;
};

export default StoreScreen;
