import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from "react";
import FirmService from "../../../services/FirmService";
import {MdPersonSearch} from "react-icons/md";
import Employee from "../../../models/Employee";
import {toast, Toaster} from "react-hot-toast";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import CreateSimpleUserRequest from "../../../models/requests/CreateSimpleUserRequest";
import Loading from "../../../commons/component/loading/views/Loading";
import {IoIosWarning} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";

export default function FirmEmployeesPage() {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [newEmployeeLoading, setNewEmployeeLoading] = useState<boolean>(false);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [loginAsEmployeeLoading, setLoginAsEmployeeLoading] = useState<boolean>(false);
    const [popup, setPopup] = useState<boolean>(false);
    const [adminPassPopup, setAdminPassPopup] = useState<boolean>(false);
    const [slugToDelete, setSlugToDelete] = useState<string | null>(null);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [addNewEmployeeSettings, setAddNewEmployeeSettings] = useState<CreateSimpleUserRequest>({
        username: '',
        email: ''
    });
    const [adminLoginToOtherAccountRequest, setAdminLoginToOtherAccountRequest] = useState({
        adminPassword: '',
        employeeSlug: ''
    });
    const {user, setUser} = useContext(AuthContext);
    const service: FirmService = new FirmService();

    useEffect(() => {
        if (user === null)
            return;

        search('');
    }, [user]);

    const debounce = (func: Function, delay: number) => {
        let timeoutId: NodeJS.Timeout;
        return (...args: any) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    const debouncedSearch = debounce((value: string) => search(value), 1000);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        setSearchTerm(event.target.value);
        debouncedSearch(event.target.value);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {id, value} = e.target;
        setAddNewEmployeeSettings((prevSettings) => ({
            ...prevSettings,
            [id]: value
        }));
    };

    const search = (value: string) => {
        if (user === null)
            return;

        setIsLoading(true);
        service.getFirmEmployees(user.firmSlug, value).then((response) => {
            setEmployees(response);
        }).catch((err) => {
            toast.error("Am intampinat o eroare. Incarca din nou")
        }).finally(() => setIsLoading(false));
    }

    const deleteEmployee = () => {
        if (user === null)
            return;

        service.deleteEmployee(user.firmSlug, slugToDelete!).then((_) => {
            setEmployees((prevEmployees) => prevEmployees.filter((employee) => employee.slug !== slugToDelete!));
            setSlugToDelete(null);
        }).catch((_) => {
            toast.error("Am intampinat o eroare la stergerea utilizatorului");
        }).finally(() => togglePopup(null));
    }

    const handlePassChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAdminLoginToOtherAccountRequest((prevSettings) => ({
            ...prevSettings,
            adminPassword: e.target.value
        }));
    };

    const loginAsEmployee = () => {
        if (user === null)
            return;

        if (adminLoginToOtherAccountRequest.adminPassword.length === 0) {
            toast.error("Nu ai introdus parola!");
            return;
        }

        setLoginAsEmployeeLoading(true);
        service.loginAsEmployee(user.firmSlug, adminLoginToOtherAccountRequest).then((response) => {
            setUser(response.data);
            navigate(ApplicationRoutes.dashboard);
        }).catch((_) => {
            toast.error("Parola ta nu a fost introdusa corect");
        }).finally(() => {
            setLoginAsEmployeeLoading(false);
            toggleAdminPassPopup(null);
        });
    }

    const addEmployee = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (addNewEmployeeSettings.email.length === 0) {
            toast.error("Emailul este gol");
            return;
        }
        if (addNewEmployeeSettings.username.length === 0) {
            toast.error("Numele angajatului este gol");
            return;
        }
        if (user === null) {
            return;
        }

        setNewEmployeeLoading(true);
        service.addEmployee(user.firmSlug, addNewEmployeeSettings).then((_) => {
            setAddNewEmployeeSettings({username: '', email: ''});
            toast.success("Angajat creat cu success");
            search(searchTerm);
        }).catch((_) => {
            toast.error("Angajatul exista deja");
        }).finally(() => setNewEmployeeLoading(false));
    }

    const togglePopup = (slug: string | null) => {
        setPopup(!popup);
        if (slug != null) {
            setSlugToDelete(slug);
        }
    }

    const toggleAdminPassPopup = (slug: string | null) => {
        setAdminPassPopup(!adminPassPopup);
        if (slug != null) {
            setAdminLoginToOtherAccountRequest((prev) => ({
                ...prev,
                employeeSlug: slug
            }));
        }
    }

    return (
        <>
            <div><Toaster/></div>
            <form onSubmit={addEmployee} className="w-full mb-5 mx-auto flex gap-3 items-end">
                <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-main-medium text-gray-900">
                        Emailul angajatului
                    </label>
                    <input type="text"
                           id="email"
                           value={addNewEmployeeSettings.email}
                           onChange={handleInputChange}
                           className="bg-gray-50 border border-gray-300 focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                </div>
                <div>
                    <label htmlFor="username" className="block mb-2 text-sm font-main-medium text-gray-900">
                        Numele angajatului
                    </label>
                    <input type="text" id="username"
                           value={addNewEmployeeSettings.username}
                           onChange={handleInputChange}
                           className="bg-gray-50 border border-gray-300 focus:outline-none text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                </div>
                <button type="submit"
                        disabled={newEmployeeLoading}
                        className={(newEmployeeLoading ? "w-16" : "w-fit") + " transition-all text-white bg-accent-main-500 font-main-medium rounded-lg text-sm px-5 py-2.5 text-center"}>
                    {
                        !newEmployeeLoading ? "Adauga angajat" : <Loading/>
                    }
                </button>
            </form>


            <form>
                <label htmlFor="default-search"
                       className="block mb-2 text-sm font-main-medium text-gray-900">Cauta aganajti</label>
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <MdPersonSearch/>
                    </div>
                    <input type="search" id="default-search"
                           className="block w-full p-4 ps-10 text-sm focus:outline-none text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                           onChange={handleSearchChange}
                           required/>
                </div>
            </form>

            <div className={"mt-4"}>
                {
                    isLoading ?
                        <div role="status" className="max-w-sm animate-pulse">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <div className="flex flex-col w-full gap-4">
                            {employees.map((employee, index) => (
                                <div key={index}
                                     className="bg-gray-200 w-full p-4 rounded-md flex justify-between items-center">
                                    <div>
                                        <p className="text-lg font-main-medium">{employee.username}</p>
                                        <p className="text-sm text-gray-600">{employee.email}</p>
                                    </div>
                                    {
                                        employee.slug !== user!.slug ?
                                            <div>
                                                <button type="button"
                                                        className="text-white bg-accent-main-500 font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                                        onClick={() => toggleAdminPassPopup(employee.slug)}>
                                                    Acceseaza cont
                                                </button>
                                                <button type="button"
                                                        className="text-white bg-error hover:bg-red-500 font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                                        onClick={() => togglePopup(employee.slug)}>
                                                    Sterge cont
                                                </button>
                                            </div>
                                            : <></>
                                    }
                                </div>
                            ))}
                        </div>
                }
            </div>

            {
                popup ?
                    <div id="popup-modal" tabIndex={-1}
                         className="bg-black/90 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-full">
                        <div className="p-4 w-full max-w-md max-h-full">
                            <div className="bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="p-4 md:p-5 text-center">
                                    <div className={"flex justify-center text-5xl my-3 text-error"}>
                                        <IoIosWarning/>
                                    </div>
                                    <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                        Sigur vrei sa stergi acest angajat?
                                    </h3>
                                    <button data-modal-hide="popup-modal" type="button"
                                            onClick={deleteEmployee}
                                            className="text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-main-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2">
                                        Da
                                    </button>
                                    <button data-modal-hide="popup-modal" type="button"
                                            onClick={() => togglePopup(null)}
                                            className="text-gray-500 bg-white focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-main-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                        Nu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
            }
            {
                adminPassPopup ?
                    <div id="popup-modal" tabIndex={-1}
                         className="bg-black/90 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full md:inset-0 h-full">
                        <div className="p-4 w-full max-w-md max-h-full">
                            <div className="bg-white rounded-lg shadow dark:bg-gray-700">
                                <div className="p-4 md:p-5 text-center">
                                    <div className={"flex justify-center text-5xl my-3 text-error"}>
                                        <IoIosWarning/>
                                    </div>
                                    <h3 className="text-lg font-main-medium text-gray-500">
                                        Sigur vrei sa te loghezi ca acest utilizator?
                                    </h3>
                                    <p className={"text-normal text-gray-400"}>
                                        Pentru confirmare, scrie-ti parola:
                                    </p>
                                    <input type="password" id="default-search"
                                           className="block w-full my-5 p-2 text-sm focus:outline-none text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                           onChange={handlePassChange}
                                           required/>
                                    <button data-modal-hide="popup-modal" type="button"
                                            onClick={loginAsEmployee}
                                            disabled={loginAsEmployeeLoading}
                                            className="text-white bg-accent-main-500 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-main-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2">
                                        {
                                            loginAsEmployeeLoading ? <Loading/> : "Login"
                                        }
                                    </button>
                                    <button data-modal-hide="popup-modal" type="button"
                                            onClick={() => toggleAdminPassPopup(null)}
                                            className="text-gray-500 bg-white focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-main-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">
                                        Nu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <></>
            }
        </>
    )
}