export enum DocumentTypeEnhanced {
    TRANSPORTATION_LICENCE = "TRANSPORTATION_LICENCE",
    EXPEDITION_LICENCE = "EXPEDITION_LICENCE",
    CMR_INSURANCE = "CMR_INSURANCE",
    ANEXES = "ANEXES",
    PAYMENT_PROOF = "PAYMENT_PROOF",
    EXPEDITION_INSURANCE = "EXPEDITION_INSURANCE",
    CI = "CI",
    TALON = "TALON",
    CUI = "CUI",
    TRANSPORTATION_CONFORM_COPY = "TRANSPORTATION_CONFORM_COPY",
    GENERAL = "GENERAL"
}
