import {useContext} from 'react';
import {AuthContext} from '../AuthContext';
import RedirectToLoginPage from "../../../../components/login/RedirectToLoginPage";
import AccessDenied from "../../../../components/login/AccessDenied";
import UserRoles from "../../../component/header/functionals/models/UserRoles";

function PrivateAccessRoute({children, permittedRole, permittedApplicationAccess}: {
    children: JSX.Element,
    permittedRole: UserRoles[],
    permittedApplicationAccess: string
}) {
    const {user} = useContext(AuthContext);

    return user == null ?
        <RedirectToLoginPage/> :
        permittedRole.includes(user.role.name) &&
        (
            (permittedApplicationAccess && permittedApplicationAccess !== "*" && user.allowedApplications.includes(permittedApplicationAccess)) ||
            permittedApplicationAccess === "*"
        ) ?
            children :
            <AccessDenied/>;

}

export default PrivateAccessRoute;
