export default function Error404() {

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="text-center">
                <h1 className="text-6xl font-main-bold text-gray-800">404</h1>
                <p className="mt-2 text-xl font-main-medium text-gray-600">Hmm... pagina nu a fost gasita</p>
                <p className="mt-4 text-gray-500">The page you are looking for might be in another universe.</p>
            </div>
        </div>
    )
}