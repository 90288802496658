import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../../../commons/functionals/authLogic/AuthContext";
import AdminService from "../../../../services/AdminService";
import Firm from "../../../../models/Firm";

export default function DashboardFirmsPage() {

    const {user} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasError, setHasError] = useState<boolean>(false);
    const [firms, setFirms] = useState<Firm[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const adminService = new AdminService();

    useEffect(() => {
        if (user !== null)
            adminService.getFirms(currentPage).then((pageOfUser) => {
                setFirms(pageOfUser.content);
                setTotalPages(pageOfUser.totalPages);
            }).catch((_) => {
                setHasError(true);
            }).finally(() => setIsLoading(false));
    }, [user, currentPage]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return (
        <>
            <h1 className={"my-4 text-2xl font-semibold"}>All firms from the apps</h1>
            {
                isLoading ?
                    <div role="status" className="max-w-sm animate-pulse">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                    </div> :
                    hasError ?
                        <div>err</div> :
                        firms && firms.length > 0 ? (
                            <>
                                <table className="min-w-full bg-white border border-gray-300">
                                    <thead>
                                    <tr>
                                        <th className="py-2 px-4 border-b">Firm Name</th>
                                        <th className="py-2 px-4 border-b">CUI</th>
                                        <th className="py-2 px-4 border-b">Address</th>
                                        <th className="py-2 px-4 border-b">Firm admin email</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {firms.map((firm: Firm) => (
                                        <tr key={firm.slug}>
                                            <td className="py-2 px-4 border-b text-center">{firm.name}</td>
                                            <td className="py-2 px-4 border-b text-center">{firm.cui ?? '-'}</td>
                                            <td className="py-2 px-4 border-b text-center">{firm.address}</td>
                                            <td className="py-2 px-4 border-b text-center">{
                                                firm.firmAdmin?.email ??
                                                <span className={"text-error"}>No admin</span>
                                            }</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>

                                <div className="flex justify-between mt-4">
                                    <button
                                        onClick={() => handlePageChange(currentPage - 1)}
                                        disabled={currentPage === 0}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Previous
                                    </button>
                                    <span className="text-gray-600">
                                        Page {currentPage + 1} of {totalPages}
                                    </span>
                                    <button
                                        onClick={() => handlePageChange(currentPage + 1)}
                                        disabled={currentPage === totalPages - 1}
                                        className="bg-accent-main-500 text-white py-2 px-4 rounded"
                                    >
                                        Next
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="text-center text-gray-600">No firms found.</div>
                        )
            }
        </>
    )
}